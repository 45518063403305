<template>
  <b-row no-gutters>
    <b-col cols="12" class="border-bottom">
      <h5 class="font-weight-bold p-2 mb-0 text-center bg-light">
        {{ subject }}
      </h5>
    </b-col>
    <b-col cols="12" class="p-2">
      <!-- INTRO -->
      <intro
        class="mb-2"
        :name="client.firstName"
        :text="$t('emails.clientBookingModification.intro', locale)"
      />

      <!-- PICTURE && ACCOMMODATION INFO -->
      <accommodation-info
        :accommodation="accommodation"
        booking-status="CONFIRMED"
        :locale="locale"
      />

      <b-divider size="lg" />

      <!-- CHECK-IN AND CHECKOUT TIMES -->
      <checkin-checkout
        :checkin="checkin"
        :checkout="checkout"
        :locale="locale"
      />

      <b-divider size="lg" />

      <!-- LOCALIZATOR -->
      <localizator :locale="locale" />

      <b-divider size="lg" />

      <!-- GUESTS -->
      <guests
        :alert-text="$t('emails.common.clientGuestsInfoAlert', locale)"
        :locale="locale"
      />

      <b-divider size="lg" />

      <!-- PRICE DETAILS -->
      <price-details :locale="locale" :link="intranetLink" />

      <b-divider size="lg" />

      <!-- SECURITY DEPOSIT -->
      <template v-if="hasSecurityDeposit">
        <security-deposit
          :alert-text="
            $t('emails.common.clientSecurityDepositInfoAlert', locale)
          "
          :locale="locale"
        />

        <b-divider size="lg" />
      </template>

      <!-- PAYMENTS -->
      <div
        v-if="
          clientPayments.length ||
          clientRefunds.length ||
          pending > 0 ||
          onsitePayments.length
        "
      >
        <h4 class="mb-2">
          {{ $t("Pagaments", locale) }}
        </h4>

        <!-- SETTLED PAYMENTS & REFUNDS -->
        <template v-if="clientPayments.length || clientRefunds.length">
          <h5>
            <u>{{ $t("Pagaments realitzats", locale) }}</u>
          </h5>
          <b-list-group flush class="my-2">
            <b-list-group-item
              v-for="payment in clientPayments"
              :key="payment.uuid"
              class="d-flex justify-content-between align-items-center"
            >
              <div>
                <div class="font-weight-bold">
                  {{ formatCurrency(payment.pvpAmount) }}
                </div>
                <div v-if="payment.method === 'SOURCE_PAYMENT'">
                  <small>
                    {{
                      $t("Gestionat per", locale, {
                        gestor: getOtaName(booking.source),
                      })
                    }}
                  </small>
                </div>
                <template v-else>
                  <div v-if="payment.date">
                    <small>
                      {{ formatDateStringToDate(payment.date) }}
                    </small>
                  </div>
                  <div v-if="payment.localizator">
                    <small> ID: {{ payment.localizator }} </small>
                  </div>
                </template>
              </div>
              <b-badge pill variant="success">
                {{ $t("Pagat", locale) }}
              </b-badge>
            </b-list-group-item>
            <b-list-group-item
              v-for="payment in clientRefunds"
              :key="payment.uuid"
              class="d-flex justify-content-between align-items-center"
            >
              <div>
                <div class="font-weight-bold">
                  {{ formatCurrency(-payment.pvpAmount) }}
                </div>
                <div v-if="payment.method === 'SOURCE_PAYMENT'">
                  <small>
                    {{
                      $t("Gestionat per", locale, {
                        gestor: getOtaName(booking.source),
                      })
                    }}
                  </small>
                </div>
                <template v-else>
                  <div v-if="payment.date">
                    <small>
                      {{ formatDateStringToDate(payment.date) }}
                    </small>
                  </div>
                  <div v-if="payment.localizator">
                    <small> ID: {{ payment.localizator }} </small>
                  </div>
                </template>
              </div>
              <b-badge pill variant="success">
                {{ $t("Reemborsat", locale) }}
              </b-badge>
            </b-list-group-item>
          </b-list-group>
        </template>

        <!-- UPCOMING PAYMENTS -->
        <div v-if="pending > 0 || onsitePayments.length">
          <h5>
            <u>{{ $t("Propers pagaments", locale) }}</u>
          </h5>
          <b-list-group flush class="my-2">
            <!-- FINAL PAYMENT -->
            <b-list-group-item
              v-if="pending > 0"
              class="d-flex justify-content-between align-items-center px-0"
            >
              <div>
                <div class="font-weight-bold">
                  {{ $t("Pagament final", locale) }}
                </div>
                <small>
                  {{ formatCurrency(pending) }} ·
                  {{ formatDateObjectToDate(finalPaymentDueDate) }}
                </small>
              </div>
              <b-badge pill variant="info">
                {{ $t("Programat", locale) }}
              </b-badge>
            </b-list-group-item>

            <!-- ONSITE PAYMENTS -->
            <client-onsite-payments
              :payments="onsitePayments"
              :locale="locale"
            />
          </b-list-group>
        </div>
      </div>

      <!-- BOOKING POLICY -->
      <template v-if="policy">
        <b-divider size="lg" />

        <booking-policies :locale="locale" @policy-updated="onPolicyUpdated" />
      </template>

      <!-- INTRANET BUTTON -->
      <template v-if="intranetLink">
        <b-divider size="lg" />

        <b-button block variant="primary" :href="intranetLink" target="blank">
          {{ $t("emails.common.intranetAccessButton", locale) }}
        </b-button>
      </template>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BBadge,
  BListGroup,
  BListGroupItem,
  BButton,
} from "bootstrap-vue";
import {
  getAccommodationTypeName,
  getGuestsText,
  getNightsText,
  getOtaName,
  getPaymentFrequencyUnit,
  getServiceName,
} from "@/utils/methods";
import {
  formatCurrency,
  formatDateObjectToDate,
  formatDateStringToDate,
} from "@/utils/formatters";
import BDivider from "@foravila-core/components/b-divider/BDivider.vue";
import Intro from "@/views/bookings/booking/components/mail/Intro.vue";
import AccommodationInfo from "@/views/bookings/booking/components/mail/AccommodationInfo.vue";
import CheckinCheckout from "@/views/bookings/booking/components/mail/CheckinCheckout.vue";
import Localizator from "@/views/bookings/booking/components/mail/Localizator.vue";
import Guests from "@/views/bookings/booking/components/mail/Guests.vue";
import PriceDetails from "@/views/bookings/booking/components/mail/ClientPriceDetails.vue";
import ClientOnsitePayments from "@/views/bookings/booking/components/mail/ClientOnsitePayments.vue";
import SecurityDeposit from "@/views/bookings/booking/components/mail/SecurityDeposit.vue";
import BookingPolicies from "@/views/bookings/booking/components/mail/BookingPolicies.vue";
import {
  AGENCY_GUESTS_INTRANET_LOGIN,
  AGENCY_GUESTS_INTRANET_REGISTER,
} from "@appConfig";

export default {
  components: {
    BRow,
    BCol,
    BBadge,
    BListGroup,
    BListGroupItem,
    BButton,
    BDivider,
    Intro,
    AccommodationInfo,
    CheckinCheckout,
    Localizator,
    Guests,
    PriceDetails,
    ClientOnsitePayments,
    SecurityDeposit,
    BookingPolicies,
  },
  props: {
    locale: {
      type: String,
      default: "en-GB",
    },
  },
  data() {
    return {
      content: {},
      getOtaName,
      formatDateStringToDate,
    };
  },
  computed: {
    accommodation() {
      return this.$store.getters["accommodation/accommodation"];
    },
    accommodationPicture() {
      return this.accommodation?.coverPicture || null;
    },
    accommodationType() {
      if (!this.accommodation) return null;
      return getAccommodationTypeName(this.accommodation.type, this.locale);
    },
    accommodationLocation() {
      if (!this.accommodation?.location) return null;
      const location = [];
      if (this.accommodation.location.city)
        location.push(this.accommodation.location.city);
      if (this.accommodation.location.region)
        location.push(this.accommodation.location.region);
      if (!location.length) return null;
      return location.join(", ");
    },
    booking() {
      return this.$store.getters["booking/booking"];
    },
    bookingGuests() {
      return (
        getGuestsText(
          {
            adults: this.booking?.adults,
            children: this.booking?.children,
            babies: this.booking?.babies,
          },
          this.locale
        ) || this.$t("No definit")
      );
    },
    checkin() {
      const formatting = {
        weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric",
      };
      return formatDateStringToDate(
        this.booking.checkin,
        this.locale,
        formatting
      );
    },
    checkout() {
      const formatting = {
        weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric",
      };
      return formatDateStringToDate(
        this.booking.checkout,
        this.locale,
        formatting
      );
    },
    policy() {
      return this.booking?.policy || null;
    },
    client() {
      return this.$store.getters["booking/client"];
    },
    averageRatePerNight() {
      return this.$store.getters["booking/averageRatePerNight"];
    },
    nightsText() {
      return getNightsText(this.booking.nights, this.locale);
    },
    subtotal() {
      return this.$store.getters["booking/subtotalDiscounted"];
    },
    total() {
      return this.$store.getters["booking/total"];
    },
    pending() {
      return this.$store.getters["booking/pending"];
    },
    hasSecurityDeposit() {
      return this.$store.getters["booking/hasSecurityDeposit"];
    },
    securityDepositPrice() {
      return this.$store.getters["booking/securityDepositPrice"];
    },
    chargableServices() {
      return this.$store.getters["booking/chargableServices"];
    },
    priceDetailsServices() {
      if (!this.chargableServices.length) return [];
      return this.chargableServices.map((bookingService) => {
        let serviceName = getServiceName(bookingService.service, this.locale);
        if (this.bookingServiceUnitPriceText(bookingService)) {
          serviceName += ` (${this.bookingServiceUnitPriceText(
            bookingService
          )})`;
        }

        let servicePrice = bookingService.pvpPrice || null;
        if (bookingService.paymentFrequency === "DAY") {
          servicePrice = bookingService.pvpPrice * this.booking.nights;
        }

        return {
          name: serviceName,
          price: servicePrice,
        };
      });
    },
    unchargableServices() {
      return this.$store.getters["booking/unchargableServices"];
    },
    clientPayments() {
      return this.$store.getters["booking/clientPayments"].filter((payment) => {
        return payment.status !== "PRE_CONFIRMED";
      });
    },
    clientRefunds() {
      return this.$store.getters["booking/clientRefunds"].filter((payment) => {
        return payment.status !== "PRE_CONFIRMED";
      });
    },
    settledPayments() {
      if (!this.clientPayments.length) return [];
      return this.clientPayments.map((payment) => ({
        price: payment.pvpAmount,
        date: this.formatPaymentDate(payment.date),
        localizator: payment.localizator,
        managed:
          payment.method === "SOURCE_PAYMENT"
            ? this.$t("Gestionat per", this.locale, {
                gestor: getOtaName(this.booking.source),
              })
            : null,
      }));
    },
    settledRefunds() {
      if (!this.clientRefunds.length) return [];
      return this.clientRefunds.map((payment) => ({
        price: -payment.pvpAmount,
        date: this.formatPaymentDate(payment.date),
        localizator: payment.localizator,
      }));
    },
    finalPaymentDueDate() {
      return this.$store.getters["booking/finalPaymentDueDate"];
    },
    onsitePayments() {
      if (!this.unchargableServices.length) return [];
      return this.unchargableServices.map((bookingService) => ({
        name: getServiceName(bookingService.service, this.locale),
        price: this.bookingServiceUnitPriceText(bookingService),
        unit: this.paymentFrequencyUnit(bookingService.paymentFrequency) || "",
      }));
    },
    contentOnsitePayments() {
      if (!this.unchargableServices.length) return [];
      return this.unchargableServices.map((bookingService) => ({
        name: getServiceName(bookingService.service, this.locale),
        price: bookingService.pvpPrice,
        unit: this.paymentFrequencyUnit(bookingService.paymentFrequency) || "",
      }));
    },
    subject() {
      return `${this.$t(
        "emails.clientBookingModification.subject",
        this.locale,
        {
          localizator: this.booking?.localizator || null,
          accommodationName: this.accommodation?.name || null,
        }
      )}`;
    },
    intranetLink() {
      if (!this.client) return null;
      if (!this.client.user)
        return `${AGENCY_GUESTS_INTRANET_REGISTER}/${this.client.uuid}?l=${this.locale}`;
      return `${AGENCY_GUESTS_INTRANET_LOGIN}?l=${this.locale}`;
    },
  },
  watch: {
    content: {
      deep: true,
      handler() {
        this.$emit("content-updated", this.content);
      },
    },
  },
  created() {
    this.initContent();
  },
  methods: {
    initContent() {
      this.content = {
        locale: this.locale,
        subject: this.subject,
        clientName: this.client?.firstName || null,
        clientEmail: this.client?.email || null,
        introHello: `Hola ${this.client?.firstName || null}`,
        introText: this.$t(
          "emails.clientBookingModification.intro",
          this.locale,
          {
            accommodationName: this.accommodationName,
          }
        ),
        guestsAlertText: this.$t(
          "emails.common.clientGuestsInfoAlert",
          this.locale
        ),
        securityDespositAlertText: this.$t(
          "emails.common.clientSecurityDepositInfoAlert",
          this.locale
        ),
        accommodationPicture: this.accommodationPicture?.url || null,
        accommodationDescription: this.accommodationType,
        accommodationName: this.accommodation?.name || null,
        accommodationLocation: this.accommodationLocation,
        checkin: this.checkin,
        checkout: this.checkout,
        localizator: this.booking?.localizator || null,
        guests: this.bookingGuests,
        averageRatePerNight: this.averageRatePerNight,
        nights: this.nightsText,
        subtotal: this.subtotal,
        priceDetailsServices: this.priceDetailsServices,
        total: this.total,
        securityDeposit: this.securityDepositPrice,
        settledPayments: this.settledPayments,
        settledRefunds: this.settledRefunds,
        finalPaymentAmount: this.pending,
        finalPaymentDueDate: formatDateObjectToDate(this.finalPaymentDueDate),
        onsitePayments: this.contentOnsitePayments,
        policies: null,
        intranetLink: this.intranetLink,
      };
    },
    onPolicyUpdated(policy) {
      this.content.policies = policy;
    },
    formatDateObjectToDate(date) {
      return formatDateObjectToDate(date);
    },
    formatPaymentDate(date) {
      return formatDateStringToDate(date);
    },
    formatCurrency(price) {
      return formatCurrency(price);
    },
    serviceName(code) {
      return getServiceName(code, this.locale);
    },
    paymentFrequencyUnit(code) {
      return getPaymentFrequencyUnit(code, this.locale);
    },
    bookingServiceUnitPriceText(bookingService) {
      if (!bookingService || !bookingService?.paymentFrequency) return null;
      if (
        bookingService.paymentFrequency === "ONCE" &&
        bookingService.chargable
      )
        return null;
      return `${this.formatCurrency(bookingService.pvpPrice)}${
        this.paymentFrequencyUnit(bookingService.paymentFrequency) || ""
      }`;
    },
  },
};
</script>
